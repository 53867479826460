import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components';
import Seo from './../components/Seo/index';
import AnimatedPageWrapper from '../components/AnimatedPageWrapper/index';

const text = [
    `This website collects anonymous data on website usage with Google Analytics. 
    Collecting this data requires the use of cookies.`,
    `This data is only used for medication math's improvement. It is not sold to any third parties.`
]
const PrivacyPage = ({data, location}) => {

  return  <AnimatedPageWrapper id="privacy-page" key="privacy-page">
    <PrivacyWrapper key="privacy">
            <Seo
              title={"Medication Math Privacy Policy"}
              description={"the privacy policy for medication math"}
              pathname={location.pathname}
            />
            <h2 className="title">Privacy page</h2>
            {text.map((e, i) => {
                return <p key={i}>{e}</p>
            })}
          </PrivacyWrapper>
  </AnimatedPageWrapper>
}
export default PrivacyPage;

const PrivacyWrapper = styled.section`
    padding: 20px 10px 10px 10px;
    max-width: ${props => props.theme.widthL};
    margin: auto;
    .title{
        text-align: center;
    }
    .statement{

    }
`

export const query = graphql`
  query PrivacyPage{
    site {
      ...SiteInformation
    }
  }
`